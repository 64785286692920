import React, {CSSProperties, useEffect, useState} from "react";
import {Image, List} from "antd-mobile";
// @ts-ignore
import {AutoSizer, List as VirtualizedList} from "react-virtualized";
import {useNavigate} from "react-router-dom";
import ManualModel from "../models/ManualModel";
import {getManuals} from "../utils/manuals";

const ManualList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<ManualModel[]>([])

    useEffect(() => {
        getManuals().then((res) => {
            if (res !== null)
                setData(res);
        });
    });

    function rowRenderer({index, key, style,}: {
        index: number
        key: string
        style: CSSProperties
    }) {
        const item = data[index]
        return (
            <List.Item
                clickable={true}
                key={key}
                style={style}
                onClick={() => navigate('/manual/' + item.id)}
                prefix={
                    <Image
                        src={item.avatar}
                        style={{borderRadius: 0}}
                        fit='cover'
                        width={40}
                        height={40}
                    />
                }>
                {item.title}
            </List.Item>
        )
    }

    return (
        <List style={{width: "98%", height: "98%"}}>
            <AutoSizer>
                {({width, height}: { width: number, height: number }) => (
                    <VirtualizedList
                        rowCount={data.length}
                        rowRenderer={rowRenderer}
                        width={width}
                        height={height}
                        rowHeight={60}
                        overscanRowCount={10}
                    />
                )}
            </AutoSizer>
        </List>
    );
}

export default ManualList;