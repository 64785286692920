import React from 'react';
import './App.css';
import {Navigate, Route, Routes, useSearchParams} from "react-router-dom";
import BottomTabNav from "./components/BottomTabNav";
import NoticeList from "./components/NoticeList";
import NoticeDetail from "./components/NoticeDetail";
import ManualList from "./components/ManualList";
import ManualDetail from "./components/ManualDetail";
import EventList from "./components/EventList";
import LicenseList from "./components/LicenseList";
import {setCookie} from "./utils/cookies";
import LicenseDetail from "./components/LicenseDetail";
import Coupon from "./components/Coupon";

function App() {
    const [searchParams,] = useSearchParams();
    const lang = searchParams.get('lang');

    if (lang !== null) {
        setCookie('lang', lang);
    }

    function WithBottomTabNav(props: { element: JSX.Element }) {
        return (
            <>
                <div className="Body">
                    {props.element}
                </div>
                <BottomTabNav/>
            </>
        );
    }

    function WithoutBottomTabNav(props: { element: JSX.Element }) {
        return (
            <>
                <div className="Body">
                    {props.element}
                </div>
            </>
        );
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Navigate to="/notice" replace={true}/>}/>
                <Route path="/notice" element={<WithBottomTabNav element={<NoticeList/>}/>}/>
                <Route path="/notice/:id" element={<WithBottomTabNav element={<NoticeDetail/>}/>}/>
                <Route path="/manual" element={<WithoutBottomTabNav element={<ManualList/>}/>}/>
                <Route path="/manual/:id" element={<WithoutBottomTabNav element={<ManualDetail/>}/>}/>
                <Route path="/event" element={<WithBottomTabNav element={<EventList/>}/>}/>
                <Route path="/license" element={<WithoutBottomTabNav element={<LicenseList/>}/>}/>
                <Route path="/license/:id" element={<WithoutBottomTabNav element={<LicenseDetail/>}/>}/>
                <Route path="/coupon" element={<WithBottomTabNav element={<Coupon/>}/>}/>
            </Routes>
        </div>
    );
}

export default App;
