import {getCookie, setCookie} from "./cookies";

export async function getFileData<T>(folder: string, defaultFile: string): Promise<T[] | null> {
    let lang = getCookie('lang') ?? 'en';

    try {
        let result = await import(`../${folder}/${lang}/${folder}.json`);
        return result.default as T[];
    } catch (error) {
        setCookie('lang', 'en');
        try {
            let result = await import(`../${folder}/en/${folder}.json`);
            return result.default as T[];
        } catch (error) {
            return null;
        }
    }
}
