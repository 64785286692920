import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Divider, Grid} from "antd-mobile";
import {Typography} from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ManualModel from "../models/ManualModel";
import Loading from "./Loading";
import NotFound from "./NotFound";
import {getManuals} from "../utils/manuals";
import {getCookie} from "../utils/cookies";

const {Text,} = Typography;

const ManualDetail = () => {
    const {id} = useParams();
    const [manual, setManual] = useState<ManualModel | null | undefined>(undefined);
    const [post, setPost] = useState('');
    const lang = getCookie('lang') ?? 'en';

    useEffect(() => {
        const id_int = parseInt(id ?? "0");

        getManuals().then((res) => {
            if (res === null) {
                setManual(null);
                return;
            }

            const data = res as ManualModel[];

            const findManual = data.find(r => r.id === id_int);

            if (findManual === undefined) {
                setManual(null);
                return;
            }

            fetch(`${process.env.PUBLIC_URL}/manuals/${findManual.file_name}`)
                .then(res => res.text())
                .then(res => {
                    setManual(findManual);
                    setPost(res);
                })
                .catch(err => console.log(err));
        });
    }, [id]);

    if (manual === undefined) {
        return <Loading/>;
    } else if (manual === null) {
        return <NotFound/>;
    }

    return (
        <Grid columns={1} gap={8} style={{width: "98%", padding: "15px"}}>
            <Grid.Item>
                <Text type="danger">Manual</Text>
            </Grid.Item>
            <Grid.Item>
                <Text strong style={{fontSize: "24px"}}>{manual.title}</Text>
            </Grid.Item>
            <Grid.Item>
                <Divider/>
            </Grid.Item>
            <Grid.Item>
                <div className="markdown"
                     style={{
                         overflow: "scroll",
                         height: "calc(100vh - 150px)",
                     }}>
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        components={{
                            img: ({node, ...props}) =>
                                <img style={{maxWidth: '100%', alignItems: 'center'}}{...props} alt=""/>
                        }}>
                        {post}
                    </ReactMarkdown>
                </div>
            </Grid.Item>
        </Grid>
    )
}

export default ManualDetail;