import {ErrorBlock} from "antd-mobile";

const NotFound = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        }}>
            <ErrorBlock status="empty" title="404 not found" description=""/>
        </div>
    )
}

export default NotFound;