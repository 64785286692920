import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Grid} from "antd-mobile";
import LicenseModel from "../models/LicenseModel";
import Loading from "./Loading";
import NotFound from "./NotFound";
import {getLicenses} from "../utils/licenses";

const LicenseDetail = () => {
    const {id} = useParams();
    const [manual, setLicense] = useState<LicenseModel | null | undefined>(undefined);
    const [post, setPost] = useState('');

    useEffect(() => {
        const id_int = parseInt(id ?? "0");

        getLicenses().then((res) => {
            if (res === null) {
                setLicense(null);
                return;
            }

            const data = res as LicenseModel[];

            const findLicense = data.find(r => r.id === id_int);

            if (findLicense === undefined) {
                setLicense(null);
                return;
            }

            fetch(`${process.env.PUBLIC_URL}/licenses/${findLicense.file_name}`)
                .then(res => res.text())
                .then(res => {
                    setLicense(findLicense);
                    setPost(res);
                })
                .catch(err => console.log(err));
        });
    }, [id]);

    if (manual === undefined) {
        return <Loading/>;
    } else if (manual === null) {
        return <NotFound/>;
    }

    return (
        <Grid columns={1} gap={8} style={{width: "98%", padding: "15px"}}>
            <Grid.Item>
                <div className="markdown"
                     style={{
                         overflow: "scroll",
                         height: "calc(96vh)",
                     }}>
                    <pre>{post}</pre>
                </div>
            </Grid.Item>
        </Grid>
    )
}

export default LicenseDetail;