import React from "react";
import {Button, Form, Input, Modal} from 'antd-mobile';
import CouponResponseModel, {CouponResponseResult} from "../models/CouponResponseModel";

interface ICouponFormValue
{
    uid: number,
    coupon_code: string
}

const Coupon = () => {
    const [form] = Form.useForm();

    const couponUseCall = async (uid: number, couponCode: string) => {
        const url = "https://localhost:7113/api/Coupon/use";
        const data = {
            couponCode: couponCode,
            uid: uid
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                return CouponResponseResult.TryAgainLater;
            }

            const responseData: CouponResponseModel = await response.json();

            return responseData.result;
        } catch (error) {
            return CouponResponseResult.TryAgainLater;
        }
    }

    const onCouponClick = async (values: ICouponFormValue) => {
        console.log(values)

        if (!values.uid) {
            await Modal.alert({
                content: "Please enter your UID",
                confirmText: "OK",
            })
        } else if (!values.coupon_code) {
            await Modal.alert({
                content: "Please enter the coupon code",
                confirmText: "OK",
            })
        } else {
            const result = await couponUseCall(values.uid, values.coupon_code);

            let message: string;
            switch (result) {
                case CouponResponseResult.Success:
                    message = "successfully redeemed";
                    break;
                case CouponResponseResult.CouponCodeNotFound:
                    message = "invalid coupon code";
                    break;
                case CouponResponseResult.AlreadyUsed:
                    message = "already used coupon";
                    break;
                case CouponResponseResult.TryAgainLater:
                    message = "Please try again";
                    break;
                case CouponResponseResult.AccountNotFound:
                    message = "UID not found";
                    break;
                default:
                    message = "Error";
            }

            await Modal.alert({
                content: message,
                confirmText: "OK",
            })

            form.setFieldValue("coupon_code", "");
        }
    };

    return (
        <div style={{display: "flex", placeSelf: "center", width: "100%"}}>
            <Form layout="vertical" style={{width: "100%"}} form={form} onFinish={onCouponClick}>
                <Form.Item label="UID" name="uid">
                    <Input placeholder="" clearable type="number"/>
                </Form.Item>
                <Form.Item label="Coupon Code" name="coupon_code">
                    <Input placeholder="" clearable/>
                </Form.Item>
                <Form.Item>
                    <Button color="primary" fill="outline" block type="submit">
                        Redeem Now
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Coupon;