export default interface CouponResponseModel {
    result: number
}

export class CouponResponseResult {
    static Success = 0;
    static CouponCodeNotFound = 1;
    static AlreadyUsed = 2;
    static TryAgainLater = 3;
    static AccountNotFound = 4;
}
