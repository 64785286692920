import React, {useEffect, useState} from "react";
import {Grid, Image} from "antd-mobile";
import EventModel from "../models/EventModel";
import {getCookie} from "../utils/cookies";
import {getEventList} from "../utils/events";
import moment from "moment/moment";
import Moment from "react-moment";

const EventList = () => {
    const lang = getCookie('lang') ?? 'en';
    const [data, setData] = useState<EventModel[]>([]);

    useEffect(() => {
        getEventList().then((res) => {
            if (res !== null) {
                const now = moment.utc();

                setData(res.filter((item) => {
                    if (item.display_start_date != null && moment.utc(item.display_start_date) > now)
                        return false;

                    if (item.display_end_date != null && moment.utc(item.display_end_date) < now)
                        return false;

                    return true;
                }));
            }
        });
    }, [lang]);

    const onClick = (message: string) => {
        // @ts-ignore
        window.UnityCall(message);
    }

    const dateRender = (item: EventModel) => {
        if (item.start_date == null && item.end_date == null)
            return (<></>);

        return (
            <>
                {item.start_date != null ?
                    <Moment format={"YYYY-MM-DD HH:mm"} local>{moment.utc(item.start_date)}</Moment> : <></>}
                &nbsp;~&nbsp;
                {item.end_date != null ?
                    <Moment format={"YYYY-MM-DD HH:mm"} local>{moment.utc(item.end_date)}</Moment> : <></>}
            </>
        );
    }

    return (
        <div style={{
            overflow: "scroll",
            width: "100%",
            height: "calc(100vh - 50px)",
        }}>
            <Grid columns={1} gap={8} style={{width: "94%", padding: "10px"}}>
                {data.map((item, index) => (
                    <Grid.Item key={index} onClick={() => onClick(item.event_name)} style={{textAlign: "center"}}>
                        <Image
                            src={item.file_name}
                            style={{borderRadius: 0, marginBottom: "5px"}}
                        />
                        {dateRender(item)}
                    </Grid.Item>
                ))}
            </Grid>
        </div>
    );
}

export default EventList;