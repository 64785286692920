import {useLocation, useNavigate} from "react-router-dom";
import {TabBar} from "antd-mobile";
import {StarOutline, BellOutline} from "antd-mobile-icons";

const BottomTabNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;

    const setRouteActive = (value: string) => {
        navigate(value);
    }

    const tabs = [
        {
            key: '/notice',
            title: 'Notice',
            icon: <BellOutline/>,
        },
        {
            key: '/event',
            title: 'Event',
            icon: <StarOutline/>,
        }
    ];

    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(tab => (
                <TabBar.Item key={tab.key} icon={tab.icon} title={tab.title}/>
            ))}
        </TabBar>
    );
};

export default BottomTabNav;