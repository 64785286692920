import React, {CSSProperties, useEffect, useState} from "react";
import {List, Image} from "antd-mobile";
// @ts-ignore
import {List as VirtualizedList, AutoSizer} from "react-virtualized";
import {useNavigate} from "react-router-dom";
import LicenseModel from "../models/LicenseModel";
import {getLicenses} from "../utils/licenses";

const LicenseList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<LicenseModel[]>([])

    useEffect(() => {
        getLicenses().then((res) => {
            if (res !== null)
                setData(res);
        });
    });

    function rowRenderer({index, key, style,}: {
        index: number
        key: string
        style: CSSProperties
    }) {
        const item = data[index]
        return (
            <List.Item
                clickable={true}
                key={key}
                style={style}
                onClick={() => navigate('/license/' + item.id)}>
                {item.title}
            </List.Item>
        )
    }

    return (
        <List style={{width: "98%", height: "100%"}}>
            <AutoSizer>
                {({width, height}: { width: number, height: number }) => (
                    <VirtualizedList
                        rowCount={data.length}
                        rowRenderer={rowRenderer}
                        width={width}
                        height={height}
                        rowHeight={60}
                        overscanRowCount={10}
                    />
                )}
            </AutoSizer>
        </List>
    );
}

export default LicenseList;