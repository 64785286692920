import {DotLoading} from "antd-mobile";

const Loading = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        }}>
            <DotLoading/>
        </div>
    );
}

export default Loading;